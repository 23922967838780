import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';


const MetaTags = () => {
  const { t } = useTranslation();




  useEffect(() => {
    // Mettre à jour le titre
    document.title = t('title');

    // Mettre à jour la description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', t('description'));
    }

  }, [t]);  // Exécuté chaque fois que la traduction change

  return null;  // Ce composant ne rend rien visuellement
};

export default MetaTags;
