import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoPremium from '../assets/logo-premium.svg';
import { useTranslation } from 'react-i18next';
import { Browser } from '@capacitor/browser';
import { getFirestore, doc, getDoc } from "firebase/firestore";

const StripeProducts = ({ user }) => {
    const { t } = useTranslation();
    const [products, setProducts] = useState([]);
    const [promoDetails, setPromoDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/myprofile');
    };

    const createCheckoutSession = async (priceId, promoId, mode) => {
        console.log('Mode:', mode); // Ajout de log pour déboguer

        setLoading(true);
        try {
            const docRef = doc(getFirestore(), 'users', user.uid);
            const docSnap = await getDoc(docRef);
            const customerId = docSnap.data().stripeCustomerId;

            const response = await fetch('https://us-central1-test-ouranos-react.cloudfunctions.net/createCheckoutSession-createCheckoutSession', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    priceId,
                    customerId,
                    mode,
                    ...(promoId && { promoId }),
                }),
            });

            if (response.ok) {
                const data = await response.json();
                await Browser.open({ url: data.url });
            } else {
                console.error('Erreur lors de la création du lien de paiement');
            }
        } catch (error) {
            console.error('Erreur lors de la requête de création du lien de paiement', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        const fetchPromoIdAndDetails = async () => {
            try {
                const promoRef = doc(getFirestore(), 'system', 'promo');
                const promoSnap = await getDoc(promoRef);
                const promoData = promoSnap.data();
                const annualPromoId = promoData.stripePromoId;
                const monthlyPromoId = promoData.stripeMonthlyPromoId;
                const oneTimePromoId = promoData.stripeOneTimePromoId; // Ajouter l'ID promo pour les achats uniques

                let promoDetails = {};

                if (annualPromoId) {
                    promoDetails.annual = await fetchPromotionDetails(annualPromoId);
                }

                if (monthlyPromoId) {
                    promoDetails.monthly = await fetchPromotionDetails(monthlyPromoId);
                }

                if (oneTimePromoId) {
                    promoDetails.oneTime = await fetchPromotionDetails(oneTimePromoId); // Récupérer les détails pour les achats uniques
                }

                setPromoDetails(promoDetails);
                fetchPricesForProduct();
            } catch (error) {
                console.error('Erreur lors de la récupération des détails de la promotion:', error);
            }
        };

        const fetchPromotionDetails = async (promoId) => {
            const functionUrl = 'https://us-central1-test-ouranos-react.cloudfunctions.net/getPromotionDetails-getPromotionDetails';
            const response = await fetch(functionUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ promoId }),
            });

            if (!response.ok) throw new Error('Failed to fetch promotion details');

            return await response.json();
        };

        fetchPromoIdAndDetails();
    }, []);


    const fetchPricesForProduct = async () => {
        const productId = 'prod_MtkAOfNwckM0Q6';
        setLoading(true);
        try {
            const response = await fetch('https://us-central1-test-ouranos-react.cloudfunctions.net/getStripeProducts', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ productId }),
            });

            if (response.ok) {
                const prices = await response.json();
                setProducts(prices);
            } else {
                console.error('Erreur lors de la récupération des prix Stripe');
            }
        } catch (error) {
            console.error('Erreur lors de la requête', error);
        } finally {
            setLoading(false);
        }
    };

    const renderProductPrice = (product) => {
        const originalPrice = product.unit_amount;
        let discountedPrice = originalPrice;
        let percentOff = null;

        if (product.recurring?.interval === 'year' && promoDetails?.annual?.coupon?.percent_off) {
            percentOff = promoDetails.annual.coupon.percent_off;
            discountedPrice = originalPrice * (1 - percentOff / 100);
        } else if (product.recurring?.interval === 'month' && promoDetails?.monthly?.coupon?.percent_off) {
            percentOff = promoDetails.monthly.coupon.percent_off;
            discountedPrice = originalPrice * (1 - percentOff / 100);
        } else if (!product.recurring && promoDetails?.oneTime?.coupon?.percent_off) { // Vérifier pour les achats uniques
            percentOff = promoDetails.oneTime.coupon.percent_off;
            discountedPrice = originalPrice * (1 - percentOff / 100);
        }

        return (
            <div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                    <p style={{ color: percentOff ? '#ffa2a2' : 'var(--white)', fontSize: '22px', margin: '0' }}>
                        {new Intl.NumberFormat(navigator.language, { style: 'currency', currency: product.currency }).format(discountedPrice / 100)}
                    </p>
                    {product.recurring ? (
                        <p style={{ color: percentOff ? '#ffa2a2' : 'var(--white)', fontSize: '16px', margin: '0' }}>
                            {product.recurring?.interval === 'month' ? t('perMonth') : t('perYear')}
                        </p>
                    ) : (
                        <p >
                            
                        </p>
                    )}

                    {percentOff && (
                        <div style={{ background: '#ffa2a2', display: 'flex', alignItems: 'center', width: 'fit-content', paddingInline: '6px', borderRadius: '6px', paddingBlock: '2px' }}>
                            <p style={{ fontWeight: 'bold', color: '#261717', fontSize: '14px', marginBlock: '0' }}>-{percentOff}%</p>
                        </div>
                    )}
                </div>
                {percentOff && (
                    <div>
                        <p>
                            <font style={{ color: 'var(--grey10)', fontSize: '20px', textDecoration: 'line-through' }}>
                                {new Intl.NumberFormat(navigator.language, { style: 'currency', currency: product.currency }).format(originalPrice / 100)}
                            </font>
                        </p>
                    </div>
                )}
            </div>
        );
    };


    // Filter products for one-time purchases and subscriptions
    // Filtrer les produits pour les achats uniques et les abonnements
    const oneTimeProducts = products.filter(product => !product.recurring);
    const subscriptionProducts = products.filter(product => product.recurring);

    return (
        <div style={{ zIndex: '1' }}>
            <div className="stripeProduct">
                <div>
                    <img style={{ width: '150px', margin: 'auto' }} src={LogoPremium} alt="Logo Ouranos Premium" />

                    {/* Prix en une fois */}
                    <div style={{ padding: '15px', backgroundColor: '#202839', borderRadius: '8px' }}>
                        <h2 style={{ marginBottom: "6px" }}>Single Purchase</h2>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                            {oneTimeProducts.map((product) => (
                                <div key={product.id} style={{ textAlign: 'left' }}>
                                    {renderProductPrice(product)}
                                    {user ? (
                                        <button
                                            className={loading ? "btn-primary-loading" : "btn-primary"}
                                            onClick={() => createCheckoutSession(
                                                product.id,
                                                promoDetails?.oneTime?.coupon?.id, // Utiliser le code promo pour les achats uniques
                                                'payment'
                                            )}
                                            disabled={loading}
                                        >
                                            {loading ? <div className="loaderLogin" /> : t('subscription')}
                                        </button>
                                    ) : null}
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Abonnements */}
                    <div style={{ padding: '15px', backgroundColor: '#202839', borderRadius: '8px', marginTop: '12px' }}>
                        <h2 style={{ marginBottom: "6px" }}>{t('subscriptions')}</h2>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                            {subscriptionProducts.map((product) => (
                                <div key={product.id} style={{ textAlign: 'left' }}>
                                    {renderProductPrice(product)}
                                    {user ? (
                                        <button
                                            className={loading ? "btn-primary-loading" : "btn-primary"}
                                            onClick={() => createCheckoutSession(
                                                product.id,
                                                product.recurring?.interval === 'year' ? promoDetails?.annual?.coupon?.id : promoDetails?.monthly?.coupon?.id,
                                                'subscription'
                                            )}
                                            disabled={loading}
                                        >
                                            {loading ? <div className="loaderLogin" /> : t('subscription')}
                                        </button>
                                    ) : null}
                                </div>
                            ))}
                        </div>
                    </div>

                    {products[1]?.product?.features && (
                        <div>
                            {products[1].product.features.map((feature, index) => (
                                <p key={index} style={{ textAlign: 'left' }}>• {feature.name}</p>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            {!user && (
                <div style={{ display: 'flex', justifyContent: 'center', maxWidth: '17em', margin: 'auto', backgroundColor: 'var(--grey20)', paddingBlock: '20px', borderRadius: '10px', paddingInline: '30px', border: '1px solid rgb(29 38 53)', marginTop: '30px' }}>
                    <div>
                        <p style={{ marginTop: '1em' }}>{t('startBy')} <a style={{ color: 'var(--grey10)', textDecoration: 'underline' }} onClick={handleLogin}>Login</a></p>
                        <p>{t('or')}</p>
                        <button className='btn-primary' onClick={handleLogin} style={{ margin: 0 }}>{t('signup')}</button>
                    </div>
                </div>
            )}
        </div>
    );

};

export default StripeProducts;
