import React, { useEffect, useState } from 'react';
import Logo from '../assets/logo-header.svg'
import { useTranslation, Trans } from 'react-i18next';


import { firestore } from '../firebase'; // Assurez-vous que le chemin d'importation est correct
import { doc, setDoc, updateDoc } from 'firebase/firestore';

const About = () => {
    const { t } = useTranslation();


    function ImportJsonToFirestore() {
        const [file, setFile] = useState(null);

        const handleFileChange = (event) => {
            const file = event.target.files[0];
            if (!file) return;

            const reader = new FileReader();
            reader.onload = (event) => {
                try {
                    const json = JSON.parse(event.target.result);
                    setFile(json);
                } catch (error) {
                    console.error("Erreur lors de la lecture du fichier JSON", error);
                }
            };
            reader.readAsText(file);
        };

        const convertDateToTimestamp = (dateString) => {
            if (!dateString) {
                console.error("La date est manquante ou invalide.");
                return null;
            }

            const [day, month, year] = dateString.split('/');
            // Le mois en JavaScript commence à 0, donc on soustrait 1
            return new Date(year, month - 1, day).getTime();
        };

        const handleSubmit = async () => {
            if (!file) {
                console.error("Aucun fichier n'a été chargé.");
                return;
            }

            try {
                // Assumant que votre JSON est une liste d'objets
                const eventsWithTimestamp = file.map(event => {
                    if (!event.hasOwnProperty('Date') || typeof event.Date !== 'string') {
                        console.error("La propriété 'Date' est manquante ou invalide pour un événement :", event);
                        return null;
                    }
                    const timestamp = convertDateToTimestamp(event.Date);
                    if (timestamp === null) {
                        console.error("La date est manquante ou invalide pour un événement :", event);
                        return null;
                    }
                    return { ...event, Date: timestamp }; // Modifier 'Date' en 'date' si nécessaire
                }).filter(event => event !== null);

                // Assumant que votre JSON est une liste d'objets
                const eventsDocRef = doc(firestore, "system", "events");
                await setDoc(eventsDocRef, { eventsData: eventsWithTimestamp }, { merge: true });
                console.log("Données importées avec succès dans Firestore");
            } catch (error) {
                console.error("Erreur lors de l'importation dans Firestore", error);
            }
        };

        return (
            <div>
                <input type="file" onChange={handleFileChange} />
                <button onClick={handleSubmit}>Importer dans Firestore</button>
            </div>
        );
    }


    return (
        <div>

         
            <h1>{t("aboutus")}</h1>

            <div className="containerinfo">

                <img style={{ display: 'block', margin: 'auto', width: '180px', paddingBlockStart: '20px', paddingBottom: '5px' }} src={Logo} alt="Logo Ouranos" />
                <p style={{ textAlign: 'left' }}>
                    {t("abouttext2")}


                    <br>
                    </br>
                    <br>
                    </br>
                    {t("abouttext1")}


                    <br></br>
                    <br></br>
                    <Trans i18nKey="abouttext3" values={{
                        link: <a target="_blank" href="https://openweathermap.org/accuracy-and-quality">{t('clickHere')}</a>
                    }}>

                    </Trans>

                </p>

            </div>
        </div>
    );
}

export default About;


