// VersionCheck.js
import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';

const VersionCheck = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const [lastUpdateString, setLastUpdateString] = useState('');

  useEffect(() => {
    const checkForUpdate = async () => {
      try {
        // Vérifiez si la clé last_update existe dans le localStorage
        const storedUpdate = localStorage.getItem('last_update');

        // Créez la clé last_update dans le localStorage si elle n'existe pas
        if (!storedUpdate) {
          const versionDocRef = doc(firestore, 'system', 'update');
          const versionDocSnapshot = await getDoc(versionDocRef);

          console.log(versionDocSnapshot)
          if (versionDocSnapshot.exists()) {
            const lastUpdate = versionDocSnapshot.data().last_update;

            // Convert Firestore Timestamp to a string in the format "YYYY-MM-DDTHH:mm:ssZ"
            const lastUpdateString = lastUpdate && lastUpdate.toDate().toISOString();

            if (lastUpdateString) {
              localStorage.setItem('last_update', lastUpdateString);
            }
          }
        } else {
          const versionDocRef = doc(firestore, 'system', 'update');
          const versionDocSnapshot = await getDoc(versionDocRef);

          if (versionDocSnapshot.exists()) {
            const lastUpdate = versionDocSnapshot.data().last_update;

            // Convert Firestore Timestamp to a string in the format "YYYY-MM-DDTHH:mm:ssZ"
            const lastUpdateString = lastUpdate && lastUpdate.toDate().toISOString();

            if (lastUpdateString) {
              // Log the values of lastUpdateString and storedUpdate for debugging
              console.log('lastUpdateString:', lastUpdateString);
              console.log('storedUpdate:', storedUpdate);

              if (storedUpdate !== lastUpdateString) {
                setIsBannerVisible(true);
                setLastUpdateString(lastUpdateString);
              }
            }
          }
        }
      } catch (error) {
        console.error('Error fetching update date from Firestore:', error);
      }
    };

    checkForUpdate(); // Check for update on component mount
  }, []);

  // Fonction pour actualiser la page et mettre à jour la clé last_update dans le localStorage
  const refreshPage = () => {
    localStorage.setItem('last_update', lastUpdateString);
    window.location.reload();
  };

  return (
    <>
      {isBannerVisible && (

        <button className="btn-primary" onClick={refreshPage} style={{paddingInline:'16px'}}>
          Refresh to update
        </button>

      )}
    </>
  );
};

export default VersionCheck;
