// Tab1.js
import React from 'react';
import TonightComponent from './TonightComponent';
import ApiComponent from './ApiComponent';
import LocationComponent from './LocationComponent';
import PlanetComponent from './PlanetComponent';
import HourForecast from './HourForecast';
import HourForecastFree from './HourForecastFree';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Area, AreaChart, Label, LabelList, ReferenceLine } from 'recharts';
import { scaleLinear } from 'd3-scale';
import { useTranslation } from 'react-i18next';


const Tab1 = ({ user, subscription }) => {
  const { t } = useTranslation();



  return (
    <div>
      <h1>{t('titletab1')}</h1>


      <LocationComponent />

      <div style={{ marginTop: '16px' }} >
        <ApiComponent>

          {({ weatherData, astroData }) => (
            <TonightComponent weatherData={weatherData} astroData={astroData} />
          )}
        </ApiComponent>

        <PlanetComponent />

        <ApiComponent>
          {({ weatherData, astroData }) => (
            <HourForecast weatherData={weatherData} astroData={astroData} />
          )}
        </ApiComponent>




      </div>



    </div>
  );
};

export default Tab1;