import React, { useState } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from '../firebase';
import { doc, setDoc, getFirestore } from 'firebase/firestore'; // Importer les fonctions Firestore
import Logo from '../assets/logo-header.svg'
import { useTranslation } from 'react-i18next';

const SignUp = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");

    const signUp = (e) => {
        e.preventDefault();

        if (!email || !password || !username) {
            alert('Please fill in all the fields.');
            return;
        }

        if (password.length < 6) {
            alert('Password should be at least 6 characters long.');
            return;
        }

        createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                const user = userCredential.user;

                // Utiliser Firestore pour stocker les informations de l'utilisateur
                try {
                    await setDoc(doc(getFirestore(), "users", user.uid), {
                        email: email,
                        username: username,
                    });
                    console.log("User information saved in Firestore");
                    alert('Account created successfully. Welcome!'); // Alerte de succès
                } catch (error) {
                    console.error("Error saving user information to Firestore", error);
                    alert('Error saving account information. Please try again.'); // Alerte en cas d'erreur lors de la sauvegarde
                }
            })
            .catch((error) => {
                console.log(error);
                alert('Error creating account. Please try again.'); // Alerte en cas d'erreur lors de la création du compte
            });

    };

    return (
        <div className="sign-in-container">
            <form onSubmit={signUp}>
                <img style={{ width: '12em', margin: 'auto', paddingBlock: '2em' }} src={Logo} alt={t('logoAltText')} />

                <h1>{t('createAccount')}</h1>
                <h2>{t('usernameLabel')}</h2>
                <input
                    autoComplete="username"
                    type="text"
                    value={username}
                    placeholder={t('usernamePlaceholder')}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <h2>{t('emailLabel')}</h2>
                <input
                    autoComplete="email"
                    type="email"
                    placeholder={t('emailPlaceholder')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <h2>{t('passwordLabel')}</h2>
                <input
                    autoComplete="new-password"
                    type="password"
                    placeholder={t('passwordPlaceholder')}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button style={{ marginTop: '20px' }} className="btn-primary" type="submit">{t('registerButton')}</button>
            </form>
        </div>

    );
};

export default SignUp;
