import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import LocationComponent from './LocationComponent';
import StripeProducts from './StripeProducts';

const LightPollutionMap = ({ subscription, latitude, longitude, user }) => { // Déstructuration des props ici

    console.log(subscription)

    const mapContainerRef = useRef(null);
    const [opacity, setOpacity] = useState(0.5);
    const [showLegend, setShowLegend] = useState(false); // Ajout de l'état pour le popup de la légende
    const [showSlider, setShowSlider] = useState(false); // Ajout de l'état pour le popup de la légende

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            mapContainerRef.current.requestFullscreen().catch(err => {
                alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
            });
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    };

    const toggleLegend = () => setShowLegend(!showLegend); // Fonction pour basculer l'affichage de la légende
    const toggleSlider = () => setShowSlider(!showSlider); // Fonction pour basculer l'affichage de la légende


    const LightPollutionLegend = () => {
        const levels = [
            { color: '#FFFFFF', range: '<17.5', description: 'Trop lumineux' },
            { color: '#808080', range: '<17.8', description: 'Extrêmement lumineux' },
            { color: '#FF0000', range: '20.5', description: 'Très lumineux' },
            { color: '#FFA500', range: '20.8', description: 'Lumineux' },
            { color: '#FFFF00', range: '21.4', description: 'Modérément sombre' },
            { color: '#008000', range: '21.8', description: 'Sombre' },
            { color: '#000080', range: '21.9', description: 'Très sombre' },
        ];

        return (
            <div style={{ padding: '10px', backgroundColor: 'rgba(0, 0, 0, 0.2)', borderRadius: '5px', maxWidth: '32em' }}>
                <div style={{
                    height: '20px',
                    borderRadius: '5px',
                    backgroundImage: 'linear-gradient(to left, #000080, #008000, #FFFF00, #FFA500, #FF0000,  #FFFFFF)',
                }} />
                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '12px', marginTop: '6px', color: 'white' }}>
                    <span>Clearer</span>

                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        {levels.map((level, index) => (
                            <span key={index} style={{ flex: 1, textAlign: 'center' }}>{level.range}</span>
                        ))}
                    </div>
                    <span>Darker</span>
                </div>
                <p style={{ marginTop: '10px', textAlign: 'left', fontSize: '14px' }}>
                    Zenith sky brightness in Magnitudes/arc square second. The higher the value, the darker the sky.
                    NASA VIIRS 2022
                </p>
            </div>
        );
    };



    useEffect(() => {

        const isValidCoordinates = latitude != 47 && longitude != -1.5;
        const center = isValidCoordinates ? [latitude, longitude] : [0, 0]; // Centre sur le monde si non défini
        const zoom = subscription === 1 ? 14 : 3;

        const svgIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="#192131" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-129.655q-11.384 0-22.371-3.788-10.988-3.789-19.744-11.942Q394.732-185 350.04-233.288q-44.692-48.289-81.019-100.673-36.327-52.385-59.597-107.231-23.269-54.846-23.269-107.769 0-134.922 87.307-218.788Q360.769-851.614 480-851.614q119.231 0 206.538 83.865 87.307 83.866 87.307 218.788 0 52.923-23.173 107.577-23.173 54.654-59.5 107.134-36.327 52.481-80.827 100.673t-87.846 87.807q-8.618 8.154-19.779 12.134-11.161 3.981-22.72 3.981Zm.114-357.845q29.001 0 49.636-20.653 20.634-20.652 20.634-49.653 0-29.001-20.692-49.636-20.693-20.634-49.75-20.634t-49.595 20.692q-20.539 20.692-20.539 49.75 0 29.057 20.653 49.595 20.652 20.539 49.653 20.539Z"/></svg>`;
        const encodedSvgIcon = encodeURIComponent(svgIcon).replace(/'/g, "%27").replace(/"/g, "%22");

        const customIcon = L.icon({
            iconUrl: `data:image/svg+xml,${encodedSvgIcon}`,
            iconSize: [42, 42], // Ajustez selon les dimensions de votre SVG
            iconAnchor: [24, 48], // Ajustez pour que le point d'ancrage soit au centre bas de l'icône
            popupAnchor: [0, -48], // Ajustez si nécessaire
        });


        const map = L.map(mapContainerRef.current, {
            center,
            zoom,
        });

        L.tileLayer('https://api.mapbox.com/styles/v1/pleiode/clu5g9e73008z01piav6q2hqd/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicGxlaW9kZSIsImEiOiJjangwY3pleG8xeGNyNDFtbzhqZ2l6NWdiIn0.trjD5sj0sj09hF3RKxHiOA', {
            attribution: '&copy; OpenStreetMap contributors'
        }).addTo(map);

        const lightPollutionLayer = L.tileLayer('https://djlorenz.github.io/astronomy/lp2022/overlay/tiles/tile_{z}_{x}_{y}.png', {
            minZoom: 2,
            maxNativeZoom: 8,
            maxZoom: 19,
            tileSize: 1024,
            zoomOffset: -2,
            opacity: opacity,
            className: 'light-pollution-tiles' // Ajoutez cette ligne
        }).addTo(map);

        // Appliquer un style de flou aux tuiles de pollution lumineuse
        lightPollutionLayer.on('tileload', function (e) {
            e.tile.style.filter = 'blur(2px)';
        });

        L.marker([latitude, longitude], { icon: customIcon }).addTo(map);

        return () => {
            map.remove();
        };
    }, [latitude, longitude, opacity]);

    return (

        <>
            <style>
                {`.leaflet-tile-container .light-pollution-tiles { filter: blur(5px); }`}
            </style>

            {subscription === 1 ?
                <>

                    <div ref={mapContainerRef} id="myMap" style={{ height: '80vh', width: '100%', borderRadius: '8px', zIndex: '0', marginTop: '12px', position: 'relative' }}  >
                        <div style={{
                            position: 'absolute',
                            top: '4%', // Positionne le div à 50% de la hauteur du parent
                            left: '50%', // Positionne le div à 50% de la largeur du parent
                            transform: 'translate(-50%, -50%)', // Déplace le div de -50% de sa propre largeur et hauteur pour le centrer exactement
                            zIndex: 1000,
                            backgroundColor: '#192131d6',
                            paddingInline: '12px',
                            borderRadius: '6px',
                            paddingBlock: '2px',
                            whiteSpace: 'nowrap'
                        }} >
                            <LocationComponent />
                        </div>

                        <button
                            onClick={(event) => {
                                event.stopPropagation();
                                toggleFullScreen();
                            }}
                            style={{ position: 'absolute', bottom: '24px', right: '5px', zIndex: 1000, width: 'auto', background: '#192131d1', paddingInline: '10px', display: 'flex', alignItems: 'center' }}
                        >
                            <svg fill="white" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 19H7C7.28333 19 7.52083 19.0958 7.7125 19.2875C7.90417 19.4792 8 19.7167 8 20C8 20.2833 7.90417 20.5208 7.7125 20.7125C7.52083 20.9042 7.28333 21 7 21H4C3.71667 21 3.47917 20.9042 3.2875 20.7125C3.09583 20.5208 3 20.2833 3 20V17C3 16.7167 3.09583 16.4792 3.2875 16.2875C3.47917 16.0958 3.71667 16 4 16C4.28333 16 4.52083 16.0958 4.7125 16.2875C4.90417 16.4792 5 16.7167 5 17V19ZM19 19V17C19 16.7167 19.0958 16.4792 19.2875 16.2875C19.4792 16.0958 19.7167 16 20 16C20.2833 16 20.5208 16.0958 20.7125 16.2875C20.9042 16.4792 21 16.7167 21 17V20C21 20.2833 20.9042 20.5208 20.7125 20.7125C20.5208 20.9042 20.2833 21 20 21H17C16.7167 21 16.4792 20.9042 16.2875 20.7125C16.0958 20.5208 16 20.2833 16 20C16 19.7167 16.0958 19.4792 16.2875 19.2875C16.4792 19.0958 16.7167 19 17 19H19ZM5 5V7C5 7.28333 4.90417 7.52083 4.7125 7.7125C4.52083 7.90417 4.28333 8 4 8C3.71667 8 3.47917 7.90417 3.2875 7.7125C3.09583 7.52083 3 7.28333 3 7V4C3 3.71667 3.09583 3.47917 3.2875 3.2875C3.47917 3.09583 3.71667 3 4 3H7C7.28333 3 7.52083 3.09583 7.7125 3.2875C7.90417 3.47917 8 3.71667 8 4C8 4.28333 7.90417 4.52083 7.7125 4.7125C7.52083 4.90417 7.28333 5 7 5H5ZM19 5H17C16.7167 5 16.4792 4.90417 16.2875 4.7125C16.0958 4.52083 16 4.28333 16 4C16 3.71667 16.0958 3.47917 16.2875 3.2875C16.4792 3.09583 16.7167 3 17 3H20C20.2833 3 20.5208 3.09583 20.7125 3.2875C20.9042 3.47917 21 3.71667 21 4V7C21 7.28333 20.9042 7.52083 20.7125 7.7125C20.5208 7.90417 20.2833 8 20 8C19.7167 8 19.4792 7.90417 19.2875 7.7125C19.0958 7.52083 19 7.28333 19 7V5Z" />
                            </svg>

                        </button>

                        <button
                            onClick={(event) => {
                                event.stopPropagation();
                                toggleLegend();
                            }}
                            style={{ position: 'absolute', bottom: '5px', left: '5px', zIndex: 1000, width: 'auto', background: '#192131d1', paddingInline: '10px', display: 'flex', alignItems: 'center' }}
                        >
                            <svg fill="white" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 17C12.2833 17 12.5208 16.9042 12.7125 16.7125C12.9042 16.5208 13 16.2833 13 16V12C13 11.7167 12.9042 11.4792 12.7125 11.2875C12.5208 11.0958 12.2833 11 12 11C11.7167 11 11.4792 11.0958 11.2875 11.2875C11.0958 11.4792 11 11.7167 11 12V16C11 16.2833 11.0958 16.5208 11.2875 16.7125C11.4792 16.9042 11.7167 17 12 17ZM12 9C12.2833 9 12.5208 8.90417 12.7125 8.7125C12.9042 8.52083 13 8.28333 13 8C13 7.71667 12.9042 7.47917 12.7125 7.2875C12.5208 7.09583 12.2833 7 12 7C11.7167 7 11.4792 7.09583 11.2875 7.2875C11.0958 7.47917 11 7.71667 11 8C11 8.28333 11.0958 8.52083 11.2875 8.7125C11.4792 8.90417 11.7167 9 12 9ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z" />
                            </svg>
                        </button>

                        <button
                            onClick={(event) => {
                                event.stopPropagation();
                                toggleSlider();
                            }}
                            style={{ position: 'absolute', bottom: '52px', left: '5px', zIndex: 1000, width: 'auto', background: '#192131d1', paddingInline: '10px', display: 'flex', alignItems: 'center' }}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.02496 14.85C3.7583 14.65 3.62913 14.3875 3.63746 14.0625C3.6458 13.7375 3.7833 13.475 4.04996 13.275C4.2333 13.1417 4.4333 13.075 4.64996 13.075C4.86663 13.075 5.06663 13.1417 5.24996 13.275L12 18.5L18.75 13.275C18.9333 13.1417 19.1333 13.075 19.35 13.075C19.5666 13.075 19.7666 13.1417 19.95 13.275C20.2166 13.475 20.3541 13.7375 20.3625 14.0625C20.3708 14.3875 20.2416 14.65 19.975 14.85L13.225 20.1C12.8583 20.3834 12.45 20.525 12 20.525C11.55 20.525 11.1416 20.3834 10.775 20.1L4.02496 14.85ZM10.775 15.05L5.02496 10.575C4.5083 10.175 4.24996 9.65002 4.24996 9.00002C4.24996 8.35002 4.5083 7.82502 5.02496 7.42502L10.775 2.95002C11.1416 2.66669 11.55 2.52502 12 2.52502C12.45 2.52502 12.8583 2.66669 13.225 2.95002L18.975 7.42502C19.4916 7.82502 19.75 8.35002 19.75 9.00002C19.75 9.65002 19.4916 10.175 18.975 10.575L13.225 15.05C12.8583 15.3334 12.45 15.475 12 15.475C11.55 15.475 11.1416 15.3334 10.775 15.05ZM12 13.45L17.75 9.00002L12 4.55002L6.24996 9.00002L12 13.45Z" fill="white" />
                            </svg>
                        </button>

                        {showLegend && (
                            <div style={{
                                position: 'absolute',
                                bottom: '5px',
                                left: '54px',
                                zIndex: 1000,
                                background: '#192131',
                                borderRadius: '6px',

                            }}>
                                <LightPollutionLegend />
                            </div>
                        )}

                        {showSlider && (
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: '5px',
                                    left: '54px',
                                    zIndex: 1000,
                                    backgroundColor: '#192131',
                                    padding: '10px',
                                    borderRadius: '6px',
                                    width:'20em'
                                }}
                            >
                                <p style={{margin:'4px'}} >Sky Brightness Opacity</p>
                                <input
                                    type="range"
                                    min="0"
                                    max="100"
                                    step="1"
                                    value={opacity * 100}
                                    onChange={(e) => setOpacity(Number(e.target.value) / 100)}
                                    style={{
                                        '-webkit-appearance': 'none',
                                        writingMode: 'bt-lr',
                                        outline: 'none',
                                        background: '#7e8aa1',
                                        width: '100%',
                                        height: '5px',
                                    }}
                                    className="custom-slider"
                                />
                                <p style={{margin:'4px'}} >{opacity * 100}%</p> {/* Affiche la valeur de l'opacity en pourcentage */}
                            </div>
                        )}

                    </div>


                </>



                :
                <div style={{ position: 'relative', height: '90vh', width: '100%', marginTop: '20px' }}>


                    <div style={{
                        position: 'absolute',
                        zIndex: 1001,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 'auto', // ou une largeur spécifique
                        padding: '20px',

                        borderRadius: '8px',

                    }}>
                        <StripeProducts user={user} />

                    </div>

                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        background: 'rgba(0, 0, 0, 0.5)', // Couleur de superposition semi-transparente
                        zIndex: 500, // Assurez-vous que cela est inférieur au zIndex de StripeProducts
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <span>Accès Premium Requis</span> {/* Message indiquant la restriction */}
                    </div>
                    <div ref={mapContainerRef} style={{ height: '100%', width: '100%', borderRadius: '8px', zIndex: '0', position: 'relative' }}>
                        <div style={{
                            position: 'absolute',
                            top: '4%', // Positionne le div à 50% de la hauteur du parent
                            left: '50%', // Positionne le div à 50% de la largeur du parent
                            transform: 'translate(-50%, -50%)', // Déplace le div de -50% de sa propre largeur et hauteur pour le centrer exactement
                            zIndex: 1000,
                            backgroundColor: '#192131d6',
                            paddingInline: '12px',
                            borderRadius: '6px',
                            paddingBlock: '2px',
                            whiteSpace: 'nowrap'
                        }} >
                            <LocationComponent />
                        </div>
                        <button
                            onClick={toggleFullScreen}
                            style={{ position: 'absolute', bottom: '24px', right: '5px', zIndex: 1000, width: 'auto', background: '#192131d1', paddingInline: '10px', display: 'flex', alignItems: 'center' }}
                        >
                            <svg fill="white" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 19H7C7.28333 19 7.52083 19.0958 7.7125 19.2875C7.90417 19.4792 8 19.7167 8 20C8 20.2833 7.90417 20.5208 7.7125 20.7125C7.52083 20.9042 7.28333 21 7 21H4C3.71667 21 3.47917 20.9042 3.2875 20.7125C3.09583 20.5208 3 20.2833 3 20V17C3 16.7167 3.09583 16.4792 3.2875 16.2875C3.47917 16.0958 3.71667 16 4 16C4.28333 16 4.52083 16.0958 4.7125 16.2875C4.90417 16.4792 5 16.7167 5 17V19ZM19 19V17C19 16.7167 19.0958 16.4792 19.2875 16.2875C19.4792 16.0958 19.7167 16 20 16C20.2833 16 20.5208 16.0958 20.7125 16.2875C20.9042 16.4792 21 16.7167 21 17V20C21 20.2833 20.9042 20.5208 20.7125 20.7125C20.5208 20.9042 20.2833 21 20 21H17C16.7167 21 16.4792 20.9042 16.2875 20.7125C16.0958 20.5208 16 20.2833 16 20C16 19.7167 16.0958 19.4792 16.2875 19.2875C16.4792 19.0958 16.7167 19 17 19H19ZM5 5V7C5 7.28333 4.90417 7.52083 4.7125 7.7125C4.52083 7.90417 4.28333 8 4 8C3.71667 8 3.47917 7.90417 3.2875 7.7125C3.09583 7.52083 3 7.28333 3 7V4C3 3.71667 3.09583 3.47917 3.2875 3.2875C3.47917 3.09583 3.71667 3 4 3H7C7.28333 3 7.52083 3.09583 7.7125 3.2875C7.90417 3.47917 8 3.71667 8 4C8 4.28333 7.90417 4.52083 7.7125 4.7125C7.52083 4.90417 7.28333 5 7 5H5ZM19 5H17C16.7167 5 16.4792 4.90417 16.2875 4.7125C16.0958 4.52083 16 4.28333 16 4C16 3.71667 16.0958 3.47917 16.2875 3.2875C16.4792 3.09583 16.7167 3 17 3H20C20.2833 3 20.5208 3.09583 20.7125 3.2875C20.9042 3.47917 21 3.71667 21 4V7C21 7.28333 20.9042 7.52083 20.7125 7.7125C20.5208 7.90417 20.2833 8 20 8C19.7167 8 19.4792 7.90417 19.2875 7.7125C19.0958 7.52083 19 7.28333 19 7V5Z" />
                            </svg>

                        </button>

                        <button
                            onClick={toggleLegend}
                            style={{ position: 'absolute', bottom: '5px', left: '5px', zIndex: 1000, width: 'auto', background: '#192131d1', paddingInline: '10px', display: 'flex', alignItems: 'center' }}
                        >
                            <svg fill="white" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 17C12.2833 17 12.5208 16.9042 12.7125 16.7125C12.9042 16.5208 13 16.2833 13 16V12C13 11.7167 12.9042 11.4792 12.7125 11.2875C12.5208 11.0958 12.2833 11 12 11C11.7167 11 11.4792 11.0958 11.2875 11.2875C11.0958 11.4792 11 11.7167 11 12V16C11 16.2833 11.0958 16.5208 11.2875 16.7125C11.4792 16.9042 11.7167 17 12 17ZM12 9C12.2833 9 12.5208 8.90417 12.7125 8.7125C12.9042 8.52083 13 8.28333 13 8C13 7.71667 12.9042 7.47917 12.7125 7.2875C12.5208 7.09583 12.2833 7 12 7C11.7167 7 11.4792 7.09583 11.2875 7.2875C11.0958 7.47917 11 7.71667 11 8C11 8.28333 11.0958 8.52083 11.2875 8.7125C11.4792 8.90417 11.7167 9 12 9ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z" />
                            </svg>
                        </button>

                        <button
                            onClick={toggleSlider}
                            style={{ position: 'absolute', bottom: '52px', left: '5px', zIndex: 1000, width: 'auto', background: '#192131d1', paddingInline: '10px', display: 'flex', alignItems: 'center' }}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.02496 14.85C3.7583 14.65 3.62913 14.3875 3.63746 14.0625C3.6458 13.7375 3.7833 13.475 4.04996 13.275C4.2333 13.1417 4.4333 13.075 4.64996 13.075C4.86663 13.075 5.06663 13.1417 5.24996 13.275L12 18.5L18.75 13.275C18.9333 13.1417 19.1333 13.075 19.35 13.075C19.5666 13.075 19.7666 13.1417 19.95 13.275C20.2166 13.475 20.3541 13.7375 20.3625 14.0625C20.3708 14.3875 20.2416 14.65 19.975 14.85L13.225 20.1C12.8583 20.3834 12.45 20.525 12 20.525C11.55 20.525 11.1416 20.3834 10.775 20.1L4.02496 14.85ZM10.775 15.05L5.02496 10.575C4.5083 10.175 4.24996 9.65002 4.24996 9.00002C4.24996 8.35002 4.5083 7.82502 5.02496 7.42502L10.775 2.95002C11.1416 2.66669 11.55 2.52502 12 2.52502C12.45 2.52502 12.8583 2.66669 13.225 2.95002L18.975 7.42502C19.4916 7.82502 19.75 8.35002 19.75 9.00002C19.75 9.65002 19.4916 10.175 18.975 10.575L13.225 15.05C12.8583 15.3334 12.45 15.475 12 15.475C11.55 15.475 11.1416 15.3334 10.775 15.05ZM12 13.45L17.75 9.00002L12 4.55002L6.24996 9.00002L12 13.45Z" fill="white" />
                            </svg>
                        </button>

                    </div>

                </div>

            }

        </>
    );
};

export default LightPollutionMap;
