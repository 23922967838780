import React from 'react';

const Subscriptionsucess = ({ user }) => {

    setTimeout(function() {
        // Rediriger l'utilisateur vers une autre page Web
        window.location.href = '/tonightforecast';
    }, 5000); 

    return (
        <div style={{ height: '60vh', margin:'auto', display:'flex', justifyContent: 'center', alignItems:'center', flexDirection:'column'}} >
            <p>Thank you for your purchase! You will be redirected on Ouranos in 5 seconds. </p>
        </div>
        
    )
};
export default Subscriptionsucess;
