import { useState, useEffect, useCallback } from 'react';

const ApiComponent = ({ children }) => {

  const [weatherData, setWeatherData] = useState(null);
  const [astroData, setAstroData] = useState(null);
  const [unit, setUnit] = useState(localStorage.getItem('unit') || 'metric');
 
  const defaultLatitude = 47;
  const defaultLongitude = -1.5;
  const defaultCityName = "Noirmoutier-en-l'Île";
  
  const storedLatitude = localStorage.getItem('latitude');
  const storedLongitude = localStorage.getItem('longitude');
  const storedCityName = localStorage.getItem('cityName');
  
  if (!storedLatitude) {
    localStorage.setItem('latitude', defaultLatitude);
  }
  
  if (!storedLongitude) {
    localStorage.setItem('longitude', defaultLongitude);
  }
  
  if (!storedCityName) {
    localStorage.setItem('cityName', defaultCityName);
  }
  
  const [latitude, setLatitude] = useState(parseFloat(storedLatitude) || defaultLatitude);
  const [longitude, setLongitude] = useState(parseFloat(storedLongitude) || defaultLongitude);
  const [cityName, setCityName] = useState(storedCityName || defaultCityName);
  



  ////////////////////OPEN WEATHER API//////
  const fetchWeatherData = useCallback(async () => {
    setWeatherData(null);


    const cachedWeatherData = localStorage.getItem('weatherData');
    const cacheTimestamp = localStorage.getItem('weatherCacheTimestamp');


    if (cachedWeatherData && cacheTimestamp) {
      const now = new Date().getTime();
      const cacheDuration = 60 * 60 * 1000;

      if (now - cacheTimestamp < cacheDuration) {
        setWeatherData(JSON.parse(cachedWeatherData));
        return;
      }
    }

    try {
      const response = await fetch(
        `https://api.openweathermap.org/data/3.0/onecall?&exclude=daily,minutely&lat=${latitude}&lon=${longitude}&appid=01afd8dde17c3e0328e43f0143e149f6&units=${unit}`
      );
      const data = await response.json();
      setWeatherData(data);

      localStorage.setItem('weatherData', JSON.stringify(data));
      localStorage.setItem('weatherCacheTimestamp', new Date().getTime());
    } catch (error) {
      console.error('Error fetching weather data:', error);
    }
  }, [latitude, longitude]);
  ///////////////////// END OPEN WEATHER API//////


  ///////////////////////WEB ASTRO API//////
  const fetchAstroData = useCallback(async () => {
    setAstroData(null); // Ajoutez cette ligne

    const cachedAstroData = localStorage.getItem('astroData');
    const cacheTimestamp = localStorage.getItem('astroCacheTimestamp');



    if (cachedAstroData && cacheTimestamp) {
      const now = new Date().getTime();
      const cacheDuration = 60 * 60 * 1000; // 1h

      if (now - cacheTimestamp < cacheDuration) {
        setAstroData(JSON.parse(cachedAstroData));
        return;
      }
    }

    try {
      const response = await fetch(
        `https://europe-west1-test-ouranos-react.cloudfunctions.net/proxy?&lat=${latitude}&long=${longitude}`
      );
      const data = await response.json();
      setAstroData(data);

      localStorage.setItem('astroData', JSON.stringify(data));
      localStorage.setItem('astroCacheTimestamp', new Date().getTime());
    } catch (error) {
      console.error('Error fetching astro data:', error);
    }
  }, [latitude, longitude]);

  ////////////////////////END WEB ASTRO API//////


  useEffect(() => {
    fetchWeatherData();
    fetchAstroData();
  }, [latitude, longitude, fetchWeatherData, fetchAstroData]);



  useEffect(() => {
    const intervalId = setInterval(() => {
      const newLatitude = parseFloat(localStorage.getItem('latitude'));
      const newLongitude = parseFloat(localStorage.getItem('longitude'));

      if (latitude !== newLatitude) {
        setLatitude(newLatitude);
      }
      if (longitude !== newLongitude) {
        setLongitude(newLongitude);
      }
    }, 1000); // Vérifie chaque seconde

    return () => {
      clearInterval(intervalId); // nettoie l'intervalle lors du démontage du composant
    };
  }, [latitude, longitude]);



  return (
    <div>
      {children({ weatherData, astroData })}
    </div>
  );
};

export default ApiComponent;
