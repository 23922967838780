import React from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const Footer = () => {
    const { t } = useTranslation();

    return (
        <div>

            <footer>
                <div>
                    <div style={{ flexDirection: 'column' }}>
                        <ul style={{ listStyle: 'none', paddingInlineStart: '0px' }}>

                            <li><a target='_blank' href="https://amusing-attic-3a5.notion.site/Enchant-0db60261f1f044be96b27cb8beaef124?pvs=4" className="text-gray-400 hover:text-gray-300">{t("aboutus")}</a></li>


                            <li>
                                <a href="mailto:contact@ouranos-app.com" >
                                    {t("contactus")}
                                </a>
                            </li>


                            <Link to="/privacy" >
                                <li><a tabIndex={1} >{t("privacypolicy")}</a></li>
                            </Link>


                            <Link to="/terms" >
                                <li><a tabIndex={1} >{t("terms")}</a></li>
                            </Link>



                            <li>
                                <a loading="lazy" target="_blank" href="https://play.google.com/store/apps/details?id=com.ouranos_app.www.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                                    <img style={{ width: '12em', margin: 'auto' }} alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
                                </a>
                            </li>

                            <li>
                                <a loading="lazy" target="_blank" href="https://apps.apple.com/us/app/ouranos-weather-astronomy/id6456041564?itsct=apps_box_badge&amp;itscg=30200">
                                    <img style={{ width: '12em', margin: 'auto' }} alt="Download on the App Store" src="https://apple-resources.s3.amazonaws.com/media-badges/download-on-the-app-store/black/en-us.svg" />
                                </a>
                            </li>
                            <li>
                                <p style={{ fontSize: '12px', fontWeight: 400 }}>Copyright © 2023 Paul Joly, all rights reserved</p>
                            </li>
                            <li>
                                <p style={{ textAlign: 'center' }}>{t("<3")}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer >

        </div >
    )
}

export default Footer