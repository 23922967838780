// Tab2.js
import React from 'react';


import DailyComponent from './DailyComponent';
import DailyComponentFree from './DailyComponentFree';
import ApiComponent from './ApiComponent';
import LocationComponent from './LocationComponent';

import { useTranslation } from 'react-i18next';

const Tab3 = ({ user, subscription }) => {
  const { t } = useTranslation();

  console.log(subscription)
  return (
    <div>
      <h1>{t('titletab3')}</h1>

      <LocationComponent />

      <div style={{ marginTop: '15px' }} ></div>
      <ApiComponent>
        {({ weatherData, astroData }) => (
          subscription == 1 ?
            <DailyComponent weatherData={weatherData} astroData={astroData} /> :
            <DailyComponentFree user={user} weatherData={weatherData} astroData={astroData} />
        )}
      </ApiComponent>


    </div>
  );
};

export default Tab3;