import React, { useEffect, useState } from 'react';
import { auth, getDatabase, ref, get, sendPasswordResetEmail } from '../firebase';

import { getFirestore, doc, getDoc, onSnapshot } from "firebase/firestore";
import { app } from '../firebase';

import { signInWithEmailAndPassword } from "firebase/auth";
import Signup from "./Signup";
import Logo from '../assets/logo-header.svg'
import { useTranslation } from 'react-i18next';
import { Browser } from '@capacitor/browser';


const Tab4 = ({ subscription }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);


  const [user, setUser] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [username, setUsername] = useState(null);
  const [buttonText, setButtonText] = useState(t("resetpassword"));
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isButtonDisabledManageBtn, setButtonDisabledManageBtn] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showSignup, setShowSignup] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(true); // New state for showing the login form
  const [customerId, setCustomerId] = useState("");
  const [fetchedIdStripe, setFetchedIdStripe] = useState("");


  const handleDeleteAccount = () => {
    if (window.confirm("Are you sure you want to delete your account and all your data? This action is irreversible.")) {
      user.delete().then(function () {
        console.log("User deleted.");
      }).catch(function (error) {
        console.error("Error deleting user:", error);
      });
    }
  };


  const signIn = (e) => {
    e.preventDefault();
    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log(userCredential);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        alert('User not found. Please check your login information.')
      });
  };


  useEffect(() => {

    const firestore = getFirestore(app);

    const unsubscribe = auth.onAuthStateChanged(user => {
      setUser(user);
      if (user) {
        setUserEmail(user.email);

        // Utilisation de Firestore pour récupérer les informations de l'utilisateur
        const userDocRef = doc(firestore, 'users', user.uid);
        getDoc(userDocRef).then(docSnap => {
          if (docSnap.exists()) {
            const userData = docSnap.data();
            const fetchedIdStripe = userData.stripeCustomerId;
            setFetchedIdStripe(fetchedIdStripe);

            // Vérifier si le username est enregistré localement
            const cachedUsername = localStorage.getItem('username');
            if (cachedUsername) {
              setUsername(cachedUsername);
            } else {
              const fetchedUsername = userData.username;
              setUsername(fetchedUsername);
              localStorage.setItem('username', fetchedUsername);
            }
          }
        });
      } else {
        // Réinitialiser les informations lorsque l'utilisateur se déconnecte
        setUserEmail(null);
        setUsername(null);
        localStorage.removeItem('username');
        setFetchedIdStripe("");
      }
    });

    return () => unsubscribe();
  }, []);






  const handleSignOut = () => {
    auth.signOut();
  };

  const sendResetEmail = async () => {
    if (!email) {
      // Affichez une alerte ou une notification à l'utilisateur pour saisir son e-mail
      alert("Please enter your email address.");
      return;
    }

    setButtonDisabled(true); // Désactivez le bouton pour éviter les clics multiples
    setButtonText('Sending reset email...'); // Optionnel: Mettez à jour le texte du bouton pour indiquer l'action en cours

    try {
      await sendPasswordResetEmail(auth, email);
      // Affichez une alerte ou une notification indiquant le succès de l'opération
      alert("Check your inbox for the password reset email.");
      setButtonText('✅ Email sent'); // Optionnel: Mettez à jour le texte du bouton pour indiquer le succès
    } catch (error) {
      // Gérez l'erreur, par exemple, en affichant un message à l'utilisateur
      alert("Failed to send reset email. Please try again.");
      console.error("Error sending password reset email:", error);
      setButtonDisabled(false); // Réactivez le bouton pour permettre une nouvelle tentative
      setButtonText('Send reset email'); // Réinitialisez le texte du bouton
    }
  };

  const handleSignup = () => {
    setShowSignup(true);
    setShowLoginForm(false); // Hide the login form when showing the signup form
  };


  const handleCustomerPortalButtonClick = async () => {
    setLoading(true);
    setButtonDisabledManageBtn(true);
    if (fetchedIdStripe) {
      try {
        const functionUrl = 'https://us-central1-test-ouranos-react.cloudfunctions.net/createCustomerPortail-createCustomerPortail'; // Remplacez par l'URL de votre fonction Firebase

        const response = await fetch(functionUrl, {
          method: 'POST',
          mode: 'cors', // Ajoutez cette option
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ customerId: fetchedIdStripe }),
        });

        if (response.ok) {
          const session = await response.json();
          await Browser.open({ url: session.url }); 
          setLoading(false);
        } else {
          console.error('Une erreur s\'est produite lors de la création de la session.');
        }
      } catch (error) {
        console.error('Une erreur s\'est produite lors de la requête.', error);
      }
    } else {
      setLoading(false);
      console.error('customerId is empty.');
      alert("Subscribe first to manage your subscription.")

    }
  };



  if (user) {
    return (

      <div style={{ minHeight: "100vh", maxWidth: '40em', marginTop: '3em' }} >

        <h1>{t('helloUser', { username })}</h1>
        <div className="ContainerText" >
          <p>{t('myEmail', { email: userEmail })}</p>
          <p>
            {t('mySubscription', { subscription: subscription === 1 ? 'Premium' : 'Freemium' })}
          </p>

        </div>
        <div className='btn-profile' >



          <button onClick={handleCustomerPortalButtonClick} disabled={loading}>
            {loading ? (
              <div className="loaderButton" />
            ) : (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '6px' }} >
                <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18">
                  <path fill='var(--white)' d="M182.152-114.022q-27.599 0-47.865-20.265-20.265-20.266-20.265-47.865v-595.696q0-27.697 20.265-48.033 20.266-20.337 47.865-20.337h242.783q14.424 0 24.244 10.012Q459-826.194 459-811.717q0 14.478-9.821 24.174-9.82 9.695-24.244 9.695H182.152v595.696h595.696v-242.783q0-14.424 9.871-24.244Q797.59-459 812.068-459q14.477 0 24.313 9.821 9.837 9.82 9.837 24.244v242.783q0 27.599-20.337 47.865-20.336 20.265-48.033 20.265H182.152Zm181.609-250q-9.326-9.804-9.826-23.385-.5-13.581 9.695-23.963l366.718-366.478H553.065q-14.424 0-24.244-9.871Q519-797.59 519-812.068q0-14.477 9.821-24.313 9.82-9.837 24.244-9.837h258.848q14.394 0 24.349 9.956 9.956 9.955 9.956 24.349v258.848q0 14.424-10.012 24.244Q826.194-519 811.717-519q-14.478 0-24.174-9.821-9.695-9.82-9.695-24.244v-176.283L411.37-362.63q-9.638 9.195-23.591 9.076-13.953-.12-24.018-10.468Z" />
                </svg>
                {t('manageBilling')}
              </div>
            )}
          </button>


          <button className='logout' onClick={handleSignOut}>{t('signOutButton')}</button>
        </div>
        <button className='btn-secondary' onClick={handleDeleteAccount}>{t('deleteAccountButton')}</button>
      </div>
    );
  } else {
    return (
      <div>
        {showLoginForm && (
          <div className="sign-in-container">
            <form onSubmit={signIn}>
              <img style={{ width: '12em', margin: 'auto', paddingBlock: '2em' }} src={Logo} alt="Logo Ouranos" />
              <h1>{t("login")}</h1>
              <h2>{t("email")}</h2>
              <input autoComplete="email" type="email" placeholder="example@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} />
              <h2>{t("password")}</h2>
              <input autoComplete="current-password" type="password" placeholder={t("passwordPlaceholder")} value={password} onChange={(e) => setPassword(e.target.value)} />

              <div style={{ marginTop: '1rem', textAlign: 'center' }}>
                <p>Forgot your password?
                  <a href="#" onClick={sendResetEmail} style={{ textDecoration: 'underline' }}>
                    {t("resetpassword")}
                  </a>
                </p>

              </div>

              <button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }} className={loading ? "btn-primary-loading" : "btn-primary"} type="submit" disabled={loading}>
                {loading ? <div className="loaderLogin" /> : t("login")}
              </button>



              <p>{t('or')}</p>
              <button style={{ marginTop: '0' }} className="btn-secondary" type="button" onClick={handleSignup}>{t("signup")}</button>
            </form>
          </div>
        )}
        {showSignup && <Signup />}
      </div>
    );
  }
};

export default Tab4;
