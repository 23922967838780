import { useState, useRef, useEffect } from 'react';
import { useLoadScript, StandaloneSearchBox } from '@react-google-maps/api';

import { getFirestore, doc, getDoc, onSnapshot } from "firebase/firestore";
import { app } from '../firebase';

import { Geolocation } from '@capacitor/geolocation';
import IconGPS from '../assets/gps.svg'
import { useTranslation } from 'react-i18next';

const API_KEY = 'AIzaSyBOxGeBmlLVcDXCh6O-RmteNwwXwEDXqyo'; // Remplacez YOUR_API_KEY par votre clé API
const libraries = ['places']; // Définissez les bibliothèques en dehors du composant

const SearchBar = ({ onPlaceSelected, user }) => {
  const { t } = useTranslation();


  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [city, setCity] = useState('');
  const [activeButton, setActiveButton] = useState(() => localStorage.getItem('unit') || 'metric');
  const [favoriteAddress, setFavoriteAddress] = useState('');
  const [favorites, setFavorites] = useState([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [useGPS, setUseGPS] = useState(false);
  const [gpsError, setGPSError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);


  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries: libraries, // Utilisez les bibliothèques définies en dehors du composant
  });

  const onLoad = (ref) => {
    searchBox.current = ref;
  };

  const handleUseGPS = async () => {


    setIsLoading(true);

    if (typeof Geolocation !== 'undefined') {
      try {
        const position = await Geolocation.getCurrentPosition();
        const { latitude, longitude } = position.coords;

        localStorage.removeItem('latitude');
        localStorage.removeItem('longitude');
        localStorage.removeItem('cityName');
        localStorage.removeItem('astroData');
        localStorage.removeItem('weatherData');

        setLatitude(latitude);
        setLongitude(longitude);


        localStorage.setItem('latitude', latitude.toString());
        localStorage.setItem('longitude', longitude.toString());

        // Obtenir le nom de l'endroit en utilisant les coordonnées de latitude et longitude
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${API_KEY}`)
          .then(response => response.json())
          .then(data => {
            if (data.results[0]) {
              const cityName = data.results[0].formatted_address;
              setCity(cityName);
              localStorage.setItem('cityName', cityName);
            }
            //window.location.reload();
            setIsLoading(false);
          })
          .catch(error => {
            console.error("Error:", error);
          });


      } catch (error) {
        setGPSError(error.message);
      }
    } else {
      setGPSError('Geolocation is not supported by this platform.');
    }
  }



  const onPlacesChanged = () => {

    localStorage.removeItem('latitude');
    localStorage.removeItem('longitude');
    localStorage.removeItem('cityName');
    localStorage.removeItem('astroData');
    localStorage.removeItem('weatherData');

    const place = searchBox.current.getPlaces()[0];
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    const cityName = place.formatted_address.split(',')[0];

    setLatitude(lat);
    setLongitude(lng);
    setCity(cityName);

    onPlaceSelected({ lat, lng, cityName });
    //window.location.reload();

    if (inputRef.current) {
      inputRef.current.value = "";
    }

  };

  const onFavoriteSelect = (favorite) => {
    const { latitude, longitude, city } = favorite;
    localStorage.removeItem('latitude');
    localStorage.removeItem('longitude');
    localStorage.removeItem('cityName');
    localStorage.removeItem('astroData');
    localStorage.removeItem('weatherData');

    localStorage.setItem('cityName', city);
    localStorage.setItem('latitude', latitude);
    localStorage.setItem('longitude', longitude);
    //window.location.reload();
  };

  const searchBox = useRef();
  const options = { types: ['(cities)'] };


  useEffect(() => {
    if (user) {
      const firestore = getFirestore(app);
      const favoritesDocRef = doc(firestore, `users/${user.uid}/ouranos/favorites`);

      const unsubscribe = onSnapshot(favoritesDocRef, (docSnap) => {
        if (docSnap.exists()) {
          const favoritesData = docSnap.data();
          const favoritesArray = Object.keys(favoritesData).map(key => favoritesData[key]);
          setFavorites(favoritesArray);
        } else {
          setFavorites([]);
        }
      }, error => {
        console.error("Error getting document:", error);
      });

      return () => unsubscribe(); // Nettoyer l'abonnement
    }
  }, [user]);




  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };


  if (!isLoaded) {
    <div style={{ width: '100%', height: '40px' }} className='ContainerSkeleton' ></div>
  }

  const saveDataToLocalStorage = (key, value, key2, value2, key3, value3) => {
    localStorage.setItem('unit', value);
    localStorage.setItem('tempUnit', value2);
    localStorage.setItem('windUnit', value3);
    setActiveButton(value);

    localStorage.removeItem('astroData');
    localStorage.removeItem('weatherData');

    window.location.reload();
  }


  return (
    <div className='header'  >


      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }} >

        {isLoaded && (
          <StandaloneSearchBox
            onLoad={onLoad}
            onPlacesChanged={onPlacesChanged}
            options={options}
          >
            <div>
              <input
                ref={inputRef}
                className={`search_bar ${isInputFocused ? 'active' : ''}`}
                type="text"
                placeholder={t('searchbar')}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
              />
              {isInputFocused && (
                <div style={{ position: "absolute", width: '15em', textAlign: 'left', boxShadow: '5px 10px 20px black', zIndex:'1001' }} className="favorites-container" >
                  {favorites.map((favorite, index) => (
                    <a key={index} className="favorite-city" onMouseDown={() => onFavoriteSelect(favorite)}>
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M15 23.0625L9.18748 26.5625C8.97914 26.6875 8.77081 26.7448 8.56248 26.7344C8.35414 26.724 8.16664 26.6562 7.99998 26.5312C7.83331 26.4062 7.70831 26.25 7.62498 26.0625C7.54164 25.875 7.52081 25.6667 7.56248 25.4375L9.12498 18.7812L3.96873 14.3125C3.78123 14.1458 3.66664 13.9635 3.62498 13.7656C3.58331 13.5677 3.59373 13.3646 3.65623 13.1562C3.69789 12.9687 3.80206 12.8073 3.96873 12.6719C4.13539 12.5365 4.33331 12.4583 4.56248 12.4375L11.375 11.8438L14 5.59375C14.1041 5.36458 14.25 5.19792 14.4375 5.09375C14.625 4.98958 14.8125 4.9375 15 4.9375C15.1875 4.9375 15.375 4.98958 15.5625 5.09375C15.75 5.19792 15.8958 5.36458 16 5.59375L18.625 11.8438L25.4375 12.4375C25.6666 12.4583 25.8646 12.5365 26.0312 12.6719C26.1979 12.8073 26.3021 12.9687 26.3437 13.1562C26.4062 13.3646 26.4166 13.5677 26.375 13.7656C26.3333 13.9635 26.2187 14.1458 26.0312 14.3125L20.875 18.7812L22.4375 25.4375C22.4791 25.6667 22.4583 25.875 22.375 26.0625C22.2916 26.25 22.1666 26.4062 22 26.5312C21.8333 26.6562 21.6458 26.724 21.4375 26.7344C21.2291 26.7448 21.0208 26.6875 20.8125 26.5625L15 23.0625Z" fill="#FFC960" />
                      </svg>
                      <p>{favorite.city}</p>
                    </a>
                  ))}
                </div>
              )}


            </div>
          </StandaloneSearchBox>


        )}

        <div>
          <button
            style={{ margin: 0, width: 'fit-content', paddingInline: '12px' }}
            className={`gps-button ${isLoading ? 'active' : ''}`}
            onClick={handleUseGPS}
            disabled={isLoading}
          >
            {isLoading ? <div className="loaderButton"></div> : <img src={IconGPS} alt="GPS" />}
          </button>


        </div>
      </div>




      <div className='btn_unit FlexClass' >




        <button className={activeButton === 'metric' ? 'active' : ''}
          onClick={() => saveDataToLocalStorage('unit', 'metric', 'tempUnit', '°', 'windUnit', 'km/h')}>
          °C
        </button>

        <button className={activeButton === 'imperial' ? 'active' : ''}
          onClick={() => saveDataToLocalStorage('unit', 'imperial', 'tempUnit', '°', 'windUnit', 'mph')}>
          °F
        </button>



      </div>
    </div>
  );
};
export default SearchBar;
