// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from '../locales/en/translation.json';
import frTranslation from '../locales/fr/translation.json';
import deTranslation from '../locales/de/translation.json';
import esTranslation from '../locales/es/translation.json';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['navigator'],
          },

        fallbackLng: 'en',
        debug: true,
     


        interpolation: {
            escapeValue: false,
        },

        resources: {
            en: {
                translation: enTranslation
            },
            fr: {
                translation: frTranslation
            },
            de: {
                translation: deTranslation
            },
            es: {
                translation: esTranslation
            }
        },
    });

export default i18n;
